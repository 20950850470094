<script setup lang="ts">
  import { MpText } from '@borg/ui';

  defineEmits<{
    (event: 'click'): void;
  }>();

  const { t } = useI18n();

  onMounted(() => {
    document.getElementById('cookieSettings')?.addEventListener('click', function (event) {
      event.preventDefault();
    });
  });
</script>

<template>
  <InfoLinksProvider>
    <template #default="{ links }">
      <ul class="links">
        <li
          v-for="link in links"
          :key="link.label"
        >
          <LinksLocale
            :to="link.to"
            target="_blank"
            @click="$emit('click')"
          >
            <MpText
              semibold
              link-plain
            >
              {{ link.label }}
            </MpText>
          </LinksLocale>
        </li>
        <li>
          <a
            id="cookieSettings"
            href=""
            data-cc="show-preferencesModal"
            >{{ t('BRAND_LINKS.COOKIES.LABEL') }}</a
          >
        </li>
      </ul>
    </template>
  </InfoLinksProvider>
</template>

<style scoped lang="scss">
  .links {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: var(--mp-space-60);
    margin: 0;

    #cookieSettings {
      text-decoration: none;
      font-weight: 600;
    }

    @include screen-lg {
      flex-direction: row;
      gap: var(--mp-space-70);
    }
  }
</style>
